import "./styles.css";
import { createRoot } from "react-dom/client";
// import Counter from "./components/Counter";
import usePartySocket from "partysocket/react";
import useSound from "use-sound";
import { useCallback } from "react";

function App() {
  const [playBite] = useSound("/sounds/bite.mp3");
  const [playMenuOpen] = useSound("/sounds/menu-open.mp3");
  const [playPopUpOff] = useSound("/sounds/pop-up-off.mp3");
  const socket = usePartySocket({
    // host defaults to the current URL if not set
    //host: process.env.PARTYKIT_HOST,
    // we could use any room name here
    room: "web-haptics",
    onMessage(evt) {
      // playSound()
      // setCount(parseInt(evt.data));
    },
  });

  const SOUNDS = { 1: playBite, 2: playMenuOpen, 9: playPopUpOff };

  const useHaptic = useCallback(
    (code: keyof typeof SOUNDS = 1) => {
      socket.send(code.toString());
      SOUNDS[code]();
    },
    [playBite, socket],
  );

  return (
    <main
      onClick={() => useHaptic(1)}
      onDoubleClick={() => useHaptic(9)}
      style={{
        display: "grid",
        gap: 32,
        padding: 24,
        gridTemplateColumns: "repeat(2,1fr)",
      }}
    >
      <h1 style={{ gridColumn: "span 2" }}>
        Introducing <code>useHaptics</code>: the missing API for haptics on the
        web
      </h1>
      <button
        onClick={() => {
          socket.send("rocket");
        }}
        style={{
          backgroundColor: "#B10DC9",
        }}
      >
        🚀
      </button>
      <button
        onClick={() => {
          socket.send("cat");
        }}
        style={{
          backgroundColor: "#FFDC00",
        }}
      >
        🐈
      </button>
      <button
        onClick={() => {
          socket.send("cat");
        }}
        style={{
          backgroundColor: "#7FDBFF",
        }}
      >
        ⏰
      </button>
      <button
        onClick={() => {
          socket.send("fairy");
        }}
        style={{
          backgroundColor: "#F012BE",
        }}
      >
        🧚
      </button>
      <p style={{ gridColumn: "span 2" }}>
        Thanks to <a href="https://partykit.io">PartyKit</a> for the real-time
        server.
      </p>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
